<template>
    <div class="main">
        <el-form :model="form" ref="form" :rules="rules" label-width="120px" :inline="true" >
            <el-form-item :label="item.label" v-for="(item, index) in formItem" :key="index">
                <el-input v-model="form[item.prop]" v-if="item.type == 'input'" :type="item.inputType" :placeholder="item.placeholder" @input="selectFun" :disabled="item.disabled"></el-input>
                <el-date-picker v-model="form[item.prop]" :type="item.dateType" :placeholder="item.placeholder" v-if="item.type == 'date'" :value-format="item.dateFormat" @blur="selectFun">
                </el-date-picker>
                <el-input-number v-model="form[item.prop]" :precision="item.precision" :step="item.step" :max="item.max" v-if="item.type == 'inputNumber'" @blur="selectFun"></el-input-number>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    props: {
        formItem: {
            type: Array,
            default: () => {
                return []
            }
        },
        rules: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            form: {}
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {
        selectFun(){
            this.$forceUpdate()
        }
    }
};
</script>
<style lang='scss' scoped>
    ::v-deep .el-form{
        .el-input-number{
            width: auto !important;
        }
    }
</style>
<template>
  <div :style="style" class="circle"></div>
</template>

<script>
import { isNumber } from "lodash-es";
export default {
  name: "CircleBadge",
  props: {
    size: {
      type: [Number, String],
      default: 10,
    },
    color: {
      type: String,
      default: "#409EFF",
    },
  },
  computed: {
    style() {
      if (isNumber(+this.size))
        return {
          width: this.size + "px",
          height: this.size + "px",
          borderRadius: this.size + "px",
          backgroundColor: this.color,
        };
      else
        return {
          width: this.size,
          height: this.size,
          borderRadius: this.size,
          backgroundColor: this.color,
        };
    },
  },
};
</script>

<style lang="scss" scoped>
.circle {
  margin-right: 4px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "songName1",
      staticClass: "content",
      on: { mouseenter: _setup.onMouseEnter, mouseleave: _setup.onMouseLeave },
    },
    [
      _c(
        "div",
        {
          class: { innerActive: _setup.nameScroll },
          style: { "animation-play-state": _setup.running },
          attrs: { id: "inner" },
        },
        [
          _c("span", { ref: "first", attrs: { id: "first" } }, [
            _vm._v(_vm._s(_vm.text)),
          ]),
          _vm._v(" "),
          _c("span", { attrs: { id: "second" } }, [_vm._v(_vm._s(_vm.text))]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { overflow: "hidden" } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
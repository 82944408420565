var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "step-content" }, [
    _c("div", { staticClass: "step-content-item" }, [
      _vm.data.state === 1
        ? _c("img", {
            staticClass: "step-item-icon",
            attrs: { src: require("@/assets/order/icon-steps-finished.svg") },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.data.state === 2
        ? _c("div", { staticStyle: { position: "relative" } }, [
            _c("img", {
              staticClass: "step-item-icon",
              attrs: { src: require("@/assets/order/circle-active.svg") },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "circle-text" }, [
              _vm._v(_vm._s(_vm.index)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.state === 0
        ? _c("div", { staticStyle: { position: "relative" } }, [
            _c("img", {
              staticClass: "step-item-icon",
              attrs: { src: require("@/assets/order/icon-oval-1-copy-23.svg") },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "circle-not-active" }, [
              _vm._v(_vm._s(_vm.index)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "step-content-label" }, [
        _c("div", [_vm._v(_vm._s(_vm.data.briefInfo))]),
        _vm._v(" "),
        _c("div", { staticClass: "step-content-time" }, [
          _c(
            "span",
            { staticStyle: { "white-space": "nowrap", "text-wrap": "nowrap" } },
            [_vm._v(_vm._s(_vm.data.createTime))]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "step-connecting-line" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
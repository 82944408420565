var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    {
      staticClass: "plain-tag",
      class: { "plain-tag-mini": _vm.type === "mini" },
      style: { backgroundColor: _vm.bgColor },
    },
    [
      _c(
        "span",
        {
          staticClass: "plain-tag-text",
          class: { "plain-tag-text-mini": _vm.type === "mini" },
          style: { color: _vm.color },
        },
        [
          _vm._t("default", function () {
            return [_c("span", [_vm._v(_vm._s(_vm.text))])]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
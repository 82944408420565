var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.visibleActions, function (item, index) {
        return _c(
          "el-button",
          _vm._b(
            {
              key: index,
              on: {
                click: function ($event) {
                  return _vm.handleMore(item.text)
                },
              },
            },
            "el-button",
            item,
            false
          ),
          [_vm._v(_vm._s(item.text))]
        )
      }),
      _vm._v(" "),
      _vm.hiddenActions.length && _vm.showMore
        ? _c(
            "el-dropdown",
            { on: { command: _vm.handleMore } },
            [
              _c(
                "span",
                {
                  staticClass: "el-dropdown-link",
                  staticStyle: { "margin-left": "10px" },
                },
                [
                  _vm._v("\n      " + _vm._s(_vm.moreText)),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.hiddenActions, function (item, index) {
                  return _c(
                    "el-dropdown-item",
                    _vm._b(
                      { key: index, attrs: { command: item.text } },
                      "el-dropdown-item",
                      item,
                      false
                    ),
                    [_vm._v(_vm._s(item.text))]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.visibleActions.length
        ? _vm._t("empty", function () {
            return [_vm._v("-")]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <el-cascader
    :key="tempKey"
    ref="cascaderAddr"
    v-model="stationArea"
    :collapse-tags="true"
    style="width: 100%"
    clearable
    :disabled="isDisabled"
    :filterable="filterable"
    :props="props"
    @change="onClear"
  ></el-cascader>
</template>

<script>
// import { administrativeRegionsQuery } from "@/api/area/administrativeRegions";
import { isEmpty } from "lodash-es";
import { getProvinceList, getCityList, getPoltList } from "@/api/common";
export default {
  name: "CascaderArea",
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isDisabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    placeholder: {
      type: String,
      default: "",
    },
    cascaderProps: {
      type: Object,
      default() {
        // 多选时可设置 { multiple: true, checkStrictly: true };
        return {};
      },
    },
    maxLevel: {
      type: Number,
      default: 2,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      props: {
        lazy: true,
        leaf: "leaf",
        lazyLoad: this.lazyLoad,
        ...this.cascaderProps,
      },
      tempKey: Math.random(),
    };
  },
  computed: {
    stationArea: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
        this.$emit("input", value);
      },
    },
  },
  methods: {
    // 省市区级联懒加载
    lazyLoad(node, resolve) {
      const { level } = node;
      if (level === 0) {
        getProvinceList()
          .then((res) => {
            let data = res.data || [];
            if (data && data.length > 0) {
              const nodes = data.map((item) => ({
                value: item.id,
                label: item.name,
                leaf: this.checkStrictly ? undefined : level >= 2,
              }));

              resolve(nodes);
            } else {
              resolve([]);
            }
          })
          .catch((e) => {
            console.log("🚀 ~ file: index.vue:99 ~ e:", e);
          });
      } else if ([1, 2].includes(level)) {
        getCityList({ pid: node.value })
          .then((res) => {
            let data = res.data || [];
            const nodes = data.map((item) => ({
              value: item.id,
              label: item.name,
              leaf: this.checkStrictly ? undefined : level >= 2,
            }));
            resolve(nodes);
          })
          .catch((e) => {
            resolve([]);
            console.log("🚀 ~ file: index.vue:122 ~ e:", e);
          });
      } else {
        resolve([]);
      }
    },
    getCheckNodes() {
      return this.$refs.cascaderAddr;
    },
    reload() {
      this.tempKey = Math.random();
    },
    onClear(val) {
      if (
        Array.isArray(val) &&
        val.length > 0 &&
        !(this.cascaderProps || {}).multiple
      ) {
        const panelRefs = this.$refs.cascaderAddr.$refs.panel;
        let activeLength = (panelRefs.activePath || {}).length;
        if (!(panelRefs.activePath[activeLength - 1].children.length > 0)) {
          panelRefs.lazyLoad(panelRefs.getCheckedNodes()[0]);
        }
      }
      if (isEmpty(val)) {
        this.$emit("clear");
      }
    },
  },
};
</script>

<style>
.el-cascader-panel .el-checkbox {
  width: inherit;
  height: 100%;
  z-index: 10;
  position: relative;
}
</style>

<style lang="scss" scoped>
::v-deep .el-input--mini .el-input__inner {
  line-height: 28px;
  text-align: center;
}
::v-deep .el-cascader__tags {
  display: flex;
  align-items: center;
}
::v-deep .el-cascader__search-input {
  min-width: 30px;
  display: flex;
  align-items: center;
}
</style>

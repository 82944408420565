var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "span",
    {
      staticClass: "card-tag",
      class: {
        ["card-tag-" + _vm.type]: !!_vm.type,
        ["card-tag__" + _vm.size]: !!_vm.size,
      },
    },
    [
      _c(
        "span",
        { staticClass: "plain-tag-text", style: _vm.color },
        [
          _vm._t("default", function () {
            return [_c("span", [_vm._v(_vm._s(_vm.text))])]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      ref: "container",
      class: [
        "el-split",
        { "el-split-vertical": _vm.direction === "vertical" },
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "el-split-pane",
          style: {
            flex:
              _vm.mode === "percentage" ? _setup.split : `${_setup.split}px`,
            ..._setup.firstPaneStyles,
          },
        },
        [_vm._t("left")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "el-split-handle",
          style: {
            cursor:
              _vm.direction === "horizontal" ? "col-resize" : "row-resize",
          },
          on: { mousedown: _setup.onHandleMouseDown },
        },
        [
          _vm._t("handle", function () {
            return [_c("div", { staticClass: "el-split-handle-inner" })]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "el-split-pane",
          style: {
            flex: _vm.mode === "percentage" ? 1 - _setup.split : undefined,
            ..._setup.secondPaneStyles,
          },
        },
        [_vm._t("right")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
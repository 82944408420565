<template>
  <div>
    <el-button
      v-for="(item, index) in visibleActions"
      :key="index"
      v-bind="item"
      @click="handleMore(item.text)"
      >{{ item.text }}</el-button
    >
    <el-dropdown v-if="hiddenActions.length && showMore" @command="handleMore">
      <span class="el-dropdown-link" style="margin-left: 10px">
        {{ moreText }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in hiddenActions"
          :key="index"
          v-bind="item"
          :command="item.text"
          >{{ item.text }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <slot name="empty" v-if="!visibleActions.length">-</slot>
  </div>
</template>

<script>
export default {
  props: {
    actions: {
      type: Array,
      required: true,
      default: () => [],
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    collapseNum: {
      type: Number,
      default: 2,
    },
    moreText: {
      type: String,
      default: "更多",
    },
  },
  data() {
    return {};
  },
  computed: {
    availableActions() {
      return this.actions.filter((item) => {
        if (item.visible === undefined) {
          return true;
        }
        return item.visible;
      });
    },
    visibleActions() {
      let res = this.availableActions.slice(0, this.collapseNum);

      if (this.collapseNum < this.availableActions.length) {
        res = this.availableActions.slice(0, Math.max(this.collapseNum - 1, 0));
      }

      return res.map((item) => {
        let { plain } = item;
        return {
          plain: plain || false,
          ...item,
        };
      });
    },
    hiddenActions() {
      let index = this.collapseNum;
      if (this.collapseNum < this.availableActions.length) {
        index = Math.max(this.collapseNum - 1, 0);
      }
      return this.availableActions.slice(index);
    },
  },
  methods: {
    handleMore(command) {
      let which = this.actions.find((item) => {
        if (command === item.text && item.click) {
          return item;
        }
      });
      which.click();
    },
  },
};
</script>

<style scoped>
/* 可以添加自定义样式 */
</style>

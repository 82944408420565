<template>
  <div class="steps">
    <slot></slot>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.steps {
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  justify-content: center;
  ::v-deep .step-content:last-child {
    flex-grow: inherit;
    .step-connecting-line {
      display: none;
    }
  }
}
</style>
